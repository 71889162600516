import * as React from "react"
import styled from "styled-components"
import { Page } from "../shared/Page/Page"
import { H1, H2 } from "../shared/Ui/Typography/Typography"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import media from "css-in-js-media"

const Container = styled.div`
    section.hero {
        display: flex;
        gap: 150rem;
        padding: 100rem 0;
        max-width: 1000rem;

        > div {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &.info-stack {
                align-items: flex-start;

                .badges {
                    display: flex;
                    gap: 10rem;
                }
            }
        }

        ${media("<desktop")} {
            flex-direction: column;

            > div.info-stack {
                justify-content: center;
                align-items: center;
                text-align: center;
            }
        }
    }
`

const UseCases = ({ location }) => {
    return (
        <Page
            location={location}
            disableCta
            constrainedFooter
            constrainedHeader
        >
            <Container>
                <section className={"hero pageConstrained"}>
                    <div className={"info-stack"}>
                        <H1>Your business phone in an app</H1>
                        <H2>
                            Route calls to the right people, and dial from
                            shared team numbers.
                        </H2>
                        <div className={"badges"}>
                            <a
                                href={
                                    "https://apps.apple.com/us/app/23shout/id1608318473"
                                }
                                target={"_blank"}
                            >
                                <StaticImage
                                    alt={"Get it on the Apple App Store"}
                                    src={"../images/app/badge-apple.png"}
                                    quality={100}
                                    placeholder={"none"}
                                    width={170}
                                />
                            </a>
                            <a
                                href={
                                    "https://play.google.com/store/apps/details?id=com.a23shout.mobile"
                                }
                                target={"_blank"}
                            >
                                <StaticImage
                                    alt={"Get it on the Google Play Store"}
                                    src={"../images/app/badge-play.png"}
                                    quality={100}
                                    placeholder={"none"}
                                    width={170}
                                />
                            </a>
                        </div>
                    </div>
                    <div className={"image"}>
                        <StaticImage
                            alt={
                                "Three screenshots of the 23shout app in different states"
                            }
                            src={"../images/app/hero.png"}
                            quality={100}
                            placeholder={"none"}
                        />
                    </div>
                </section>
            </Container>
        </Page>
    )
}

const Chevron = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="currentColor"
            className={"bottom-link-chevron"}
        >
            <path d="M9.29 6.71c-.39.39-.39 1.02 0 1.41L13.17 12l-3.88 3.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41L10.7 6.7c-.38-.38-1.02-.38-1.41.01z" />
        </svg>
    )
}

export default UseCases
